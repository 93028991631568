import React, { Component } from 'react';
import Include from '../../../../drupal/Include';
import Text from '../../../atoms/Text/Text';
import { Image } from 'react-bootstrap';
import Modal from '../../../ui/Modal/Modal';
import RemoteVideo from '../../media/RemoteVideo/RemoteVideo';
import { IonIcon } from '@ionic/react';
import { playCircle } from 'ionicons/icons';


import './HeroItem.css';

export default class HeroItem extends Component {

    state = {
        modalOpen: false
    };

    render() {
        let title = null;
        let body = null;
        let link = null;
        let image = null;
        let modal = null;
        let linkObj = null;

        if(this.props.item instanceof Include) {
            if(this.props.item.get('field_link')) {
                linkObj = this.props.item.get('field_link');
                link = (
                    <a href={linkObj.url}>{linkObj.title}</a>
                );

                if(image) {
                    image = (
                        <a href={linkObj.url}>{image}</a>
                    );
                }

                if(title) {
                    title = (
                        <a href={linkObj.url}>{title}</a>
                    );
                }
            }

            const media = this.props.item.get('field_hero_image');
            if(media.get('field_media_image')) {
                image = (
                    <Image role="button" src={this.props.item.get('field_hero_image').get('field_media_image').get('links').ajff_org_hero?.href} />
                );

                if(linkObj) {
                    image = (<a href={linkObj.url}>{image}</a>);
                }
            } else if(media.get('field_media_oembed_video')) {
                image = (
                    <div className="video">
                        <Image role="button" src={media.get('thumbnail').get('links').ajff_org_hero?.href} onClick={this.toggleModal} />
                        <div onClick={this.toggleModal} className="video-indicator" role="button"><IonIcon icon={playCircle} /> Watch Video</div>
                    </div>
                );
                
                if(this.state.modalOpen) {
                    modal = (
                        <Modal modalType="video" handleClose={this.toggleModal}>
                            <RemoteVideo url={media.get('field_media_oembed_video')} />
                        </Modal>
                    );
                    }
            }

            if(this.props.item.get('field_title')) {
                title = (
                    <h2 role="button">{this.props.item.get('field_title')}</h2>
                );

                if(linkObj) {
                    title = (<a href={linkObj.url}>{title}</a>);
                }
            }

            if(this.props.item.get('field_body')) {
                body = (
                    <Text text={this.props.item.get('field_body').processed} />
                )
            }

        }

        return (
            <div className="hero-item row">
                    <div className="image col-12 col-lg-8">
                        {image}
                    </div>

                    <div className="content col-12 col-lg-4">
                        <div className="title">
                            {title}
                        </div>

                        <div className="hero-item-details">
                            {body}
                            {link}
                        </div>
                    </div>

                    {modal}
            </div>
        );
    }

    toggleModal = () => {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }
}
